<template>
  <div
    :class="{
      'bg-[#008e7d] shadow-[0_0_0.5em_0_#008e7d]': color === 'dark-cyan',
      'bg-[#333333] shadow-[0_0_0.5em_0_#333333]': color === 'neutral',
      'bg-[#da0062] shadow-[0_0_0.5em_0_#da0062]': color === 'crimson',
      'bg-[#c900da] shadow-[0_0_0.5em_0_#c900da]': color === 'dark-violet',
      'bg-[#00af46] shadow-[0_0_0.5em_0_#00af46]': color === 'sea-green',
    }"
    class="mt-2 flex flex-col items-center justify-center whitespace-nowrap rounded-md border-2 border-white px-2 text-center text-lg font-bold text-white/90"
  >
    <div v-if="socials.length > 0" class="-mt-2.5 flex items-center justify-center gap-0.5">
      <div
        v-for="icon in socials"
        :key="icon"
        class="relative flex h-5 w-5 items-center justify-center rounded-md bg-zinc-100 text-black"
      >
        <StickerIcons :icon="icon" class="h-2.5 w-2.5" />
      </div>
    </div>
    <TextSticker
      ref="sticker"
      variant="none"
      @update-rect="(e) => emit('updateRect', e)"
      @update-content="(e) => emit('updateContent', e)"
      @update="(e) => emit('update', e)"
      @sticker-loaded="(e) => emit('stickerLoaded', e)"
      :html-content="htmlContent"
    />
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../../StickerIcons.vue'
import { computed, ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // 'twitch', 'youtube' or 'kick'
  icon: {
    type: String,
    default: '["twitch", "youtube", "kick"]',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})
const socials = computed(() => JSON.parse(props.icon))

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.none {
  padding: 0.2em 0.2em 0.3em 0.2em;
  line-height: 24px;
  font-size: 24px;
  font-weight: bold;
}
</style>
