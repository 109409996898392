<template>
  <div
    :class="{
      'bg-black/80 text-white/90': color === 'dark',
      'bg-[#4267B2]/80 text-white/90': color === 'facebook',
      'bg-[#ff0000]/80 text-white/90': color === 'youtube',
      'bg-[#53FC19]/80 text-black/90': color === 'kick',
      'bg-[#9146ff]/80 text-white/90': color === 'twitch',
    }"
    class="inline-flex whitespace-nowrap rounded-md backdrop-blur-sm"
  >
    <div
      class="flex flex-1 items-center gap-2 rounded-md border-[2px] border-white px-2 py-0.5 font-bold"
      style="filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))"
    >
      <StickerIcons :icon="icon" class="h-[1.25em] w-[1.25em]" />

      <div class="text-wrapper">
        <TextSticker
          ref="sticker"
          variant="none"
          @update-rect="(e) => emit('updateRect', e)"
          @update-content="(e) => emit('updateContent', e)"
          @update="(e) => emit('update', e)"
          @sticker-loaded="(e) => emit('stickerLoaded', e)"
          :html-content="htmlContent"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../StickerIcons.vue'
import { ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // 'twitch', 'youtube' or 'kick'
  icon: {
    type: String,
    default: 'twitch',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.text-wrapper {
  padding: 0;

  .none {
    font-size: 1.5em;
    line-height: 2rem;
    font-weight: bold;
  }
}
</style>
