<template>
  <div class="wrapper outline-0" :class="[variant, color]" :style="computedCssStyle">
    <span
      class="text"
      spellcheck="false"
      ref="text"
      @input="update"
      @blur="updateContent"
      @keydown.esc="updateContent"
      @paste="paste"
      @click.prevent="setEditMode"
      :contenteditable="isEditing"
      v-html="htmlContent"
    />
  </div>
</template>

<script>
import { isValidHex, hexToRGBA } from '@/services/hexColorService'
import { fontsData } from '@/data/fonts'
import TimelineElement from '@/components/Stickers/StickerAnimator.vue'
import compatabilityChecker from '@/services/compatabilityChecker'

export default {
  components: { TimelineElement },
  props: {
    // Either: padded, outline, glow, transparent, regular or none
    variant: {
      type: String,
      default: 'padded',
    },
    // Maps to a hardcoded CSS Class, differs per Variant
    color: {
      type: String,
    },
    // Start and end time in the GSAP timeline. In milliseconds
    start: {
      type: Number,
      default: -1,
    },
    end: {
      type: Number,
      default: -1,
    },
    // En-/disables setEditMode() function
    editable: {
      type: Boolean,
      default: true,
    },
    // The actual HTML text to display
    htmlContent: {
      type: String,
      default: 'Enter<br/> text here',
    },
    // If set, these override the Text and Secondary colors
    primaryColor: {
      type: String,
    },
    secondaryColor: {
      type: String,
    },
    // Override the used font-family
    fontFamily: {
      type: String,
    },
  },
  data() {
    return {
      isEditing: false,
    }
  },
  mounted() {
    // Set at the end of the callstack to fix the sticker position not being set correctly initially
    setTimeout(() => {
      this.$emit('stickerLoaded', this)
    }, 0)
  },
  computed: {
    // Gets the actual used Font. Is either given by Prop `fontFamily` or it depends on the Prop `variant`
    computedFontFamily() {
      if (this.fontFamily) {
        return this.fontFamily
      }

      switch (this.variant) {
        case 'outline':
          return 'bangers'
        case 'outline-2':
          return 'Luckiest Guy'
        case 'fat-montserrat':
          return 'Montserrat Black'
        case 'padded':
        case 'glow':
        case 'transparent':
        case 'regular':
          return 'metropolis'

        default:
          return 'metropolis'
        // throw new Error(`Unknown variant '${this.variant}' in TextSticker`)
      }
    },
    // Get the lineHeight. TODO for custom font-uploads, we probably want to send this in the StickerRenderer's JSON
    lineHeightFactor() {
      const computedFontFamily = this.computedFontFamily.toLowerCase()
      const fontObj = fontsData.fonts.find((e) => e.label.toLowerCase() === computedFontFamily)
      return fontObj.lineHeightFactor
    },
    // Defines CSS vars and other overrides based on JS props
    computedCssStyle() {
      const cssVars = {
        '--line-height-factor': this.lineHeightFactor,
        '--font-family': this.computedFontFamily,
        // 600 was the previous font-weight for metropolis, so we keep it for backwards compatibility
        '--font-weight': this.computedFontFamily === 'metropolis' ? '600' : '400',
      }

      // if primaryColor is a valid hex, set it as CSS var for the font-color
      if (this.primaryColor && isValidHex(this.primaryColor)) cssVars['--text-color'] = this.primaryColor

      if (this.secondaryColor) {
        cssVars['--secondary-color'] = this.secondaryColor

        // If the secondary is a valid hex, we transform it to an RGBA to add opacity.
        cssVars['--background-color-rgba'] = isValidHex(this.secondaryColor)
          ? hexToRGBA(this.secondaryColor, 0.5)
          : this.secondaryColor
      }

      return cssVars
    },
  },
  methods: {
    update() {
      this.$emit('updateRect')
    },
    updateContent(e) {
      this.isEditing = false
      window.getSelection()?.removeAllRanges()
      this.$emit('updateContent', e.target.innerHTML)
    },
    paste(e) {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        range.deleteContents()
        const textNode = document.createTextNode(text)
        range.insertNode(textNode)
      }
    },
    setEditMode() {
      if (!this.editable) {
        return
      }

      this.isEditing = true

      setTimeout(() => {
        // without timeout this doesn't work
        this.$refs.text.focus()
        if (compatabilityChecker.isMobile()) return
        window.getSelection().selectAllChildren(this.$refs.text)
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
/**
   * CSS styles
   */
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 800;
  src: local('Bangers'), url('/fonts/Bangers/Bangers-Regular.ttf') format('truetype');
}

/**
   * Shared wrapper css rules
   */
.wrapper {
  display: block;
  line-height: calc(var(--line-height-factor) * 1.5 * 16px);
  font-family: var(--font-family);
  font-weight: var(--font-weight);

  text-align: center;
  width: auto;
  user-select: none;

  .text {
    z-index: 9999;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    --caret-color: rgb(43, 209, 215);
    caret-color: var(--caret-color);
  }

  * {
    box-sizing: content-box;
  }
}

/**
   * Padded style
   */
.padded {
  padding: 0.25em 0;
  font-size: 16px;

  > .text {
    white-space: nowrap;
    padding: 0.5em;
    border-radius: 10px;
    color: var(--text-color);
    background: var(--secondary-color);
    caret-color: var(--caret-color);
  }

  &:deep(.highlight) {
    background: var(--text-color);
    color: var(--secondary-color);
  }

  &.white {
    --text-color: black;
    --secondary-color: white;
  }

  &.black {
    --text-color: white;
    --secondary-color: black;
  }

  &.red {
    --text-color: white;
    --secondary-color: #ea4040;
    --caret-color: black;
  }

  &.orange {
    --text-color: white;
    --secondary-color: #ff923d;
  }

  &.yellow {
    --text-color: white;
    --secondary-color: #f2ce46;
  }

  &.pink {
    --text-color: white;
    --secondary-color: #f8d7e8;
  }

  &.green {
    --text-color: white;
    --secondary-color: #77c25d;
  }

  &.lightblue {
    --text-color: white;
    --secondary-color: #3496ef;
  }
}

/**
   * Regular style
   */
.regular {
  padding: 0.25em 0;
  font-size: 16px;

  > .text {
    padding: 0.5em;
    border-radius: 10px;
    color: var(--text-color);
    caret-color: var(--caret-color);
  }

  &.white {
    --text-color: white;
  }

  &.black {
    --text-color: black;
  }

  &.red {
    --text-color: red;
  }

  &.orange {
    --text-color: #ff923d;
  }

  &.yellow {
    --text-color: #f2ce46;
  }

  &.pink {
    --text-color: #f8d7e8;
  }

  &.green {
    --text-color: #77c25d;
  }

  &.lightblue {
    --text-color: #3496ef;
  }
}

/**
   * Outline/stroke style
   */
.outline {
  line-height: calc(var(--line-height-factor) * 1.2 * 16px);
  padding: 0.25em 0.25em;

  > .text {
    border-radius: 4px;
    color: var(--text-color);

    font-weight: 800;
    font-size: 24px;
    -webkit-text-stroke: 1.2px var(--secondary-color);

    &:deep(.highlight) {
      color: yellow;
    }
  }

  &.black {
    --text-color: black;
    --secondary-color: white;
  }

  &.white {
    --text-color: white;
    --secondary-color: black;
  }

  &.red {
    --text-color: #ea4040;
    --secondary-color: white;
  }

  &.pink {
    --text-color: #f8d7e8;
    --secondary-color: #c66f92;
  }

  &.orange {
    --text-color: #ff923d;
    --secondary-color: #7a3500;
  }

  &.yellow {
    --text-color: #f2ce46;
    --secondary-color: #665106;
  }

  &.green {
    --text-color: #77c25d;
    --secondary-color: white;
  }

  &.lightblue {
    --text-color: #3496ef;
    --secondary-color: white;
  }
}

/**
   * Outline 2 /stroke style
   */
.outline-2 {
  line-height: calc(var(--line-height-factor) * 1.2 * 16px);
  padding: 0.25em 0.25em;
  font-weight: 900;

  > .text {
    border-radius: 4px;
    color: var(--text-color);

    font-weight: 100;
    font-size: 24px;
    -webkit-text-stroke: 1.5px var(--secondary-color);
  }

  &.black {
    --text-color: black;
    --secondary-color: white;
  }
  &.white {
    --text-color: white;
    --secondary-color: black;
  }
  &.red {
    --text-color: red;
    --secondary-color: black;
  }
  &.pink {
    --text-color: #ff00d4;
    --secondary-color: black;
  }
  &.orange {
    --text-color: #ff6f00;
    --secondary-color: black;
  }
  &.yellow {
    --text-color: #fff200;
    --secondary-color: black;
  }
  &.green {
    --text-color: #00ff00;
    --secondary-color: black;
  }
  &.lightblue {
    --text-color: #0084ff;
    --secondary-color: black;
  }
}

/** Fat montserrat */
.fat-montserrat {
  line-height: calc(var(--line-height-factor) * 1.2 * 16px);
  padding: 0.25em 0.25em;
  text-transform: uppercase;

  > .text {
    border-radius: 4px;
    color: var(--text-color);

    font-weight: 900;
    font-size: 24px;
    text-shadow: var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px,
      var(--secondary-color) 1px 0 4px, var(--secondary-color) 1px 0 4px;
  }

  &.black {
    --text-color: black;
    --secondary-color: white;
  }
  &.white {
    --text-color: white;
    --secondary-color: black;
  }
  &.red {
    --text-color: red;
    --secondary-color: black;
  }
  &.pink {
    --text-color: #ff00d4;
    --secondary-color: black;
  }
  &.orange {
    --text-color: #ff6f00;
    --secondary-color: black;
  }
  &.yellow {
    --text-color: #fff200;
    --secondary-color: black;
  }
  &.green {
    --text-color: #00ff00;
    --secondary-color: black;
  }
  &.lightblue {
    --text-color: #0084ff;
    --secondary-color: black;
  }
}
/**
   * Glow style
   */
.glow {
  > .text {
    color: var(--text-color);
    text-shadow: var(--secondary-color) 1px 0 10px, var(--secondary-color) 1px 0 10px, var(--secondary-color) 1px 0 10px,
      var(--secondary-color) 1px 0 10px;
  }

  &.blue {
    --text-color: white;
    --secondary-color: rgb(8, 8, 241);
  }

  &.red {
    --text-color: white;
    --secondary-color: #ea4040;
  }

  &.yellow {
    --text-color: white;
    --secondary-color: rgb(255, 255, 0);
  }

  &.pink {
    --text-color: white;
    --secondary-color: #f8d7e8;
  }
}

/**
   * Transparent padded style
   */
.transparent {
  padding: 0.25em 0;
  line-height: calc(var(--line-height-factor) * 2.1 * 16px);
  font-size: 16px;

  > .text {
    z-index: 9999;

    font-family: var(--font-family);

    padding: 0.5em;
    color: var(--text-color);
    background: var(--background-color-rgba, rgba(0, 0, 0, 0.5));
    border-radius: 10px;
  }

  &.white {
    --text-color: white;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.black {
    --text-color: black;
    --secondary-color: rgba(255, 255, 255, 0.5);
  }

  &.red {
    --text-color: #ea4040;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.orange {
    --text-color: #ff923d;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.yellow {
    --text-color: #f2ce46;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.pink {
    --text-color: #f8d7e8;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.green {
    --text-color: #77c25d;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }

  &.lightblue {
    --text-color: #3496ef;
    --secondary-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
