<template>
  <div :class="color" class="generic-a">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => $emit('updateRect', e)"
        @update-content="(e) => $emit('updateContent', e)"
        @update="(e) => $emit('update', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import StickerIcons from '../../StickerIcons.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    StickerIcons,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    // Either blue, red, green
    color: {
      type: String,
      default: 'blue',
    },
    // Either twitch or youtube
    icon: {
      type: String,
      default: 'twitch',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
.generic-a {
  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;
  width: auto;
  padding: 0.5em;
  color: #ffffff;
  align-items: center;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    font-family: 'Metropolis';
    border: 1px solid;
    margin-left: -1px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-left-width: 0;

    .none {
      padding: 0.2em;
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    display: flex;
    align-items: center;
    padding: 0.5em;
    border: 1px solid;
    border-radius: 10px;

    > svg {
      width: 1.5em;
    }
  }
}

.dark {
  .logo {
    background: #9146ff;
    border-color: rgba(70, 8, 158, 0.5);
  }

  .text-wrapper {
    background: linear-gradient(269.81deg, #9146ff 0.15%, #27035a 99.84%);
    border-color: rgba(70, 8, 158, 0.5);
  }
}

.color {
  .logo {
    background: rgba(185, 159, 228, 1);
    border-color: rgba(70, 8, 158, 0.5);
  }

  .text-wrapper {
    background: linear-gradient(269.81deg, #ff575b 0.15%, #b99fe4 99.84%);
    border-color: rgba(70, 8, 158, 0.5);
  }
}

.light {
  .logo {
    background: rgba(255, 131, 83, 1);
    border-color: rgba(70, 8, 158, 0.5);
  }

  .text-wrapper {
    background: linear-gradient(269.81deg, #00b0ff 0.15%, #ff575b 99.84%);
    border-color: rgba(70, 8, 158, 0.5);
  }
}
</style>
