<template>
  <div :class="color" class="generic-a">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => emit('updateRect', e)"
        @update-content="(e) => emit('updateContent', e)"
        @update="(e) => emit('update', e)"
        @sticker-loaded="(e) => emit('stickerLoaded', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../StickerIcons.vue'
import { ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // Either twitch or youtube
  icon: {
    type: String,
    default: 'twitch',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.generic-a {
  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  width: auto;
  //padding: 0.5em;
  color: #ffffff;

  border-radius: 8px;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin: 0.25em 0.3em 0.3em 0;
    border-radius: 5px;
    font-family: 'Metropolis';

    padding: 0.2em;

    .none {
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 21px;
    }
  }

  > .logo {
    padding: 0.5em;

    display: flex;
    align-items: center;

    > svg {
      width: 1.5em;
    }
  }
}

.dark {
  background: #000000;

  > .text-wrapper {
    background-color: #ffffff;
    color: #000000;
  }
}

.color {
  background: #9146ff;

  > .text-wrapper {
    background-color: #ffffff;
    color: #9146ff;
  }
}

.light {
  background: #ffffff;
  color: #9146ff;

  > .text-wrapper {
    background-color: #9146ff;
    color: #ffffff;
  }
}
</style>
