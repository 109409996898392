<template>
  <div :class="color" class="generic-a">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => emit('updateRect', e)"
        @update-content="(e) => emit('updateContent', e)"
        @update="(e) => emit('update', e)"
        @sticker-loaded="(e) => emit('stickerLoaded', e)"
        :html-content="htmlContent"
      />
    </div>

    <div style="position: absolute; left: 50%; bottom: 55%">
      <div class="follow" style="position: relative; left: -50%">Follow me</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../StickerIcons.vue'
import { ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // Either twitch or youtube
  icon: {
    type: String,
    default: 'twitch',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.generic-a {
  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;
  position: relative;
  width: auto;
  //padding: 0.5em;
  color: #ffffff;
  min-width: 150px;

  justify-content: center;

  border-radius: 99999999px;

  border-bottom: transparent 4px solid;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    align-items: center;
    font-family: 'Metropolis', sans-serif;
    z-index: 1;

    padding: 0.8em 0.75em 0.75em 0;

    .none {
      font-size: 23px;
      line-height: 23px;
      font-style: normal;
      font-weight: bold;
    }
  }

  > .logo {
    padding: 0.5em;

    display: flex;
    align-items: center;

    > svg {
      width: 1.5em;
    }
  }

  .follow {
    position: absolute;
    text-align: center;
    background-color: black;
    display: block;
    border-radius: 9999999px;
    //width: 100%;
    margin-left: auto;
    height: 20px;
    padding: 3px 10px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 10px;
    transform-origin: center;
  }
}

.dark {
  background: #000000;
  border-bottom-color: #9146ff;

  > .logo {
    //background: #9146ff;
  }

  .follow {
    background-color: #000000;
  }
}

.color {
  background: #9146ff;
  border-bottom-color: #000000;

  > .logo {
    //background: #000000;
  }
}

.light {
  background: #ffffff;
  color: #9146ff;
  border-bottom-color: #9146ff;

  .follow {
    background-color: #ffffff;
  }

  > .logo {
    //background: #9146ff;
    //color: #ffffff;
  }
}
</style>
