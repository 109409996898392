<template>
  <div ref="element">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useTimeLineAnimation } from '@/components-v2/modules/VideoPlayer/useVideoManager'
import type { AnimationStyle, AnimationTime, StickerData } from '@/store/editor/editorStickers'
import { toValue, useElementSize } from '@vueuse/core'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorMainStore } from '@/store/editor/editorMain'

const props = defineProps<{
  animationStyle: AnimationStyle
  animationTime: AnimationTime
  sticker: StickerData
  containerWidth: number
  duration?: number
  startTime?: number
  endTime?: number
}>()

const element = ref<HTMLElement | null>(null)
const { width } = useElementSize(element)

const duration = 5
const opacity = 0

const editorMainStore = useEditorMainStore()

const start = computed(() => (props.startTime || editorMainStore.trimmedStartTime) / 1000)
const end = computed(() => (props.endTime || editorMainStore.trimmedEndTime) / 1000)

useTimeLineAnimation([() => props.animationTime, () => props.animationStyle, element, start, end], (globalTimeline) => {
  if (!props.animationTime || !props.animationStyle || !element.value) return
  const totalDuration = end.value - start.value
  if (props.animationStyle === 'none') {
    if (props.animationTime === 'start') {
      globalTimeline
        .set(
          element.value,
          {
            opacity: 1,
          },
          start.value
        )
        .to(
          element.value,
          {
            opacity: opacity,
            duration: 0,
          },
          start.value + duration
        )
    }
    if (props.animationTime === 'end') {
      globalTimeline
        .set(
          element.value,
          {
            opacity: opacity,
          },
          0
        )
        .to(
          element.value,
          {
            opacity: 1,
            duration: 0,
          },
          end.value - duration
        )
    }
    if (props.animationTime === 'middle') {
      globalTimeline
        .set(
          element.value,
          {
            opacity: opacity,
          },
          0
        )
        .to(
          element.value,
          {
            opacity: 1,
            duration: 0,
          },
          totalDuration / 2 - duration / 2
        )
        .to(
          element.value,
          {
            opacity: opacity,
            duration: 0,
            delay: duration,
          },
          '>'
        )
    }
  }
  if (props.animationStyle === 'slide') {
    const _width = toValue(width)
    const left = props.sticker.x * props.containerWidth
    const centerPosition = left + _width / 2
    const isLeftOfCenter = centerPosition < props.containerWidth / 2
    const animationDistance = isLeftOfCenter ? (left + _width) * -1 : props.containerWidth - left

    if (props.animationTime === 'start') {
      globalTimeline
        .set(
          element.value,
          {
            x: 0,
          },
          0
        )
        .to(
          element.value,
          {
            x: animationDistance,
            duration: 1,
          },
          duration
        )
    }
    if (props.animationTime === 'end') {
      globalTimeline
        .set(
          element.value,
          {
            x: animationDistance,
          },
          0
        )
        .to(
          element.value,
          {
            x: 0,
            duration: 1,
          },
          globalTimeline.duration() - duration
        )
    }
    if (props.animationTime === 'middle') {
      globalTimeline
        .set(
          element.value,
          {
            x: animationDistance,
          },
          0
        )
        .to(
          element.value,
          {
            x: 0,
            duration: 1,
          },
          globalTimeline.duration() / 2 - duration / 2
        )
        .to(
          element.value,
          {
            x: animationDistance,
            duration: 1,
            delay: duration,
          },
          '>'
        )
    }
  }
})
</script>

<style lang="scss" scoped></style>
