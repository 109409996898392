<template>
  <div class="wrapper">
    <img class="sprinkle" style="width: 15px; top: 10px; left: 3px" src="/images/svgs/sprinkle.svg" />
    <img class="sprinkle" style="width: 10px; top: 35px; left: 30px" src="/images/svgs/sprinkle.svg" />
    <img class="sprinkle" style="width: 15px; top: 0px; right: 20px" src="/images/svgs/sprinkle.svg" />
    <img class="sprinkle" style="width: 10px; top: 20px; right: 5px" src="/images/svgs/sprinkle.svg" />
    <img
      @load="onAssetsLoaded"
      class="sprinkle"
      style="width: 6px; top: 35px; right: 40px"
      src="/images/svgs/sprinkle.svg"
    />

    <div class="twitch-d">
      <div class="logo">
        <twitch-icon />
      </div>

      <div class="text-wrapper">
        <TextSticker
          ref="sticker"
          variant="none"
          font-family="Elsie Swash Caps"
          @update-rect="(e) => $emit('updateRect', e)"
          @update-content="(e) => $emit('updateContent', e)"
          @update="(e) => $emit('update', e)"
          :html-content="htmlContent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import TwitchIcon from '../Icons/SocialMedia/TwitchIcon.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    TwitchIcon,
  },
  props: {
    htmlContent: {
      type: String,
      default: ' ',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  methods: {
    onAssetsLoaded() {
      this.$emit('stickerLoaded', this)
    },
  },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'ElsieSwashCaps';
  font-style: normal;
  font-weight: 400;
  src: local('ElsieSwashCaps'), url('/fonts/ElsieSwashCaps/ElsieSwashCaps-Regular.ttf') format('truetype');
}

.wrapper {
  position: relative;
  display: inline-block;

  .sprinkle {
    position: absolute;
  }
}

.twitch-d {
  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  background: linear-gradient(269.79deg, #e907a9 0.14%, #a305db 97.62%);
  border-radius: 7.5px;

  width: auto;
  padding: 0.5em 2em;

  color: #ffffff;
  margin-top: 60px;
  margin-left: 12px;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'ElsieSwashCaps';

    .none {
      padding-left: 2px;
      font-weight: bold;
      font-style: normal;
      font-size: 24px;
      line-height: 23px;
    }
  }
  > .logo {
    display: flex;
    align-items: center;

    > svg {
      width: 1.7em;
      height: 1.7em;
    }
  }
}
</style>
