<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core'
import { onMounted, ref } from 'vue'
import type { Feature } from '@/data/features'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'

const emit = defineEmits<{
  (name: 'resize', rect: DOMRect): void
  (name: 'click', event: MouseEvent): void
}>()

defineProps<{ feature?: Feature; selected?: boolean }>()

const button = ref<HTMLButtonElement>()

function resize() {
  if (button.value) {
    const domRect = button.value.getBoundingClientRect()
    button.value.style.minHeight = domRect.width + 'px'
    emit('resize', domRect)
  }
}

useResizeObserver(button, () => resize())
onMounted(() => resize())
</script>

<template>
  <button
    @click="(e) => emit('click', e)"
    type="button"
    ref="button"
    class="relative flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-2 text-company-primary"
    :class="{
      'border-cyan-400/50 bg-cyan-400/10': selected,
      'border-gray-100 bg-white hover:border-cyan-400/50 hover:bg-cyan-400/10 active:border-cyan-400/50 active:bg-cyan-400/20':
        !selected,
    }"
  >
    <slot />
    <div class="absolute right-2 top-2">
      <DynamicPlanButtonWithTooltip v-if="feature" :feature="feature" />
    </div>
  </button>
</template>

<style scoped lang="scss"></style>
